import "../style/banner.scss";
import { BrandLogo, CloseLogo } from "../images/svj";
import { useState, useEffect } from "react";
import request from "../utils/request";

const Banner = ({ close, heading, para, terms, onClose }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [sentResponse, setSentResponse] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [responseState, setResponseState] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validateEmail = (email) => {
    // Email regex pattern for basic validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    const domain = email.split("@")[1];

    // List of common free email domains
    const commonFreeEmailDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "aol.com",
      "hotmail.co.uk",
      "hotmail.fr",
      "msn.com",
      "yahoo.fr",
      "wanadoo.fr",
      "orange.fr",
      "comcast.net",
      "yahoo.co.uk",
      "yahoo.com.br",
      "yahoo.co.in",
      "live.com",
      "rediffmail.com",
      "free.fr",
      "gmx.de",
      "web.de",
      "yandex.ru",
      "ymail.com",
      "libero.it",
      "outlook.com",
      "uol.com.br",
      "bol.com.br",
      "mail.ru",
      "cox.net",
      "hotmail.it",
      "sbcglobal.net",
      "sfr.fr",
      "live.fr",
      "verizon.net",
      "live.co.uk",
      "googlemail.com",
      "yahoo.es",
      "inbox.com",
      "icloud.com",
      "me.com",
      "mail.com",
      "yahoo.de",
      "t-online.de",
      "yahoo.it",
      "volny.cz",
      "163.com",
      "seznam.cz",
      "yahoo.com.au",
      "yahoo.com.mx",
      "yahoo.ca",
      "yahoo.co.jp",
      "protonmail.com",
      "ymail.com",
      "zoho.com",
    ];

    const isWorkDomain = !commonFreeEmailDomains.includes(domain.toLowerCase());

    return isWorkDomain;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setSentResponse(null);
    // Call the email validator function here
    if (value === "") {
      setIsValid(true); // Input is empty, so consider it as valid (hide error message)
    } else {
      const isValidEmail = validateEmail(value);
      setIsValid(isValidEmail);
    }
  };

  const isLargeScreen = screenSize > 992;

  const sendMail = async () => {
    try {
      const response = await request.post("/frgm/record", {
        email,
      });
      setSentResponse(response?.data?.msg);
      setResponseState(response.status);
      if (response.status === 200) {
        setEmail(" ");
      }
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.data?.errors[0]?.message ===
        "User with this email already exists."
      ) {
        const msg = (
          <p>
            This email is already registered. Please reach out to us at{" "}
            <a
              href="mailto:beta@fragmatic.io"
              target="_blank"
              style={{ color: "#62ab78", fontSize: "14px" }}
            >
              beta@fragmatic.io
            </a>{" "}
            for any questions
          </p>
        );
        setSentResponse(msg);
      } else {
        setSentResponse("Something went wrong");
      }
      setResponseState(error.response.status);
    }
  };
  const clickHanlder = (e) => {
    e.preventDefault();
    if (!email.length) {
      setSentResponse("Please enter a valid business email address.");
      setResponseState(500);
      return;
    }
    if (!isValid) return;
    sendMail();
  };
  return (
    <>
      <div
        className={`banner ${
          isLargeScreen ? "banner_desktop" : "banner_mobile"
        }`}
      >
        <div className="banner_box drop-in">
          <div className="banner_logo drop-in">
            <BrandLogo />
            {close ? (
              <div className="close" onClick={() => onClose()}>
                Close <CloseLogo />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="banner_heading ">
            <h1 className="drop-up">{heading}</h1>
          </div>
          <span className="text beta-up">
            <p>{para}</p>
          </span>
          <div className="input_box input-up">
            <form method="post" id="fragmaticEmailForm" onSubmit={clickHanlder}>
              <input
                id="myInput"
                type="email"
                placeholder="Email address"
                value={email}
                onChange={handleInputChange}
              />
              <button className="Banner_btn">Join Beta</button>
            </form>
          </div>
          <div className="input_box input-up">
            {!isValid && (
              <p style={{ color: "#d73c3c" }}>
                Please enter a valid business email address.
              </p>
            )}
            {sentResponse ? (
              <p
                style={
                  responseState === 500
                    ? { color: "#d73c3c" }
                    : { color: "white" }
                }
              >
                {sentResponse}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
